import React, { useState, useEffect } from 'react';
// import logo from './logo.svg';
import './App.css';

function App() {
  const [apiStatus, setApiStatus] = useState('Checking...');

  useEffect(() => {
    // Define the function to check the API status
    const checkApiStatus = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL_BASE + '/api/v1/health/check/');
        const status = response.status === 200 ? 'OK' : `FAILED CODE ${response.status}`;
        setApiStatus(`API Status: ${status}`)
      } catch (error) {
        setApiStatus('API Status: FAILED');
      }
    };

    // Call the function on component mount
    checkApiStatus();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <code>onprem.ai</code>
      </header>
      {/* API status display */}
      <p className="api-status">{`${new Date().getFullYear()} | ${apiStatus}`}</p>
    </div>
  );
}

export default App;
